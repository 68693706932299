import React, { useState } from "react";
import { SkillProvider, SkillTreeGroup, SkillGroupDataType } from "../bst_fork";
import { Link } from "gatsby";
import Confetti from "./Confetti";
import StatsCard from "./StatsCard";
import { caliSkillsTreeTheme } from "../constants";
import Loader from "./Loader";
import LoginPromptListener from "./listeners/LoginPromptListener";
import FilterInput from "./FilterInput";
import SupportPrompt from "./SupportPrompt";
import "../styles/CalisthenicsSkillTree.css";
import SkillTreeContainers from "./SkillTreeContainers";
import useFocusQuery from "../hooks/useFocusQuery";
import useSkillsQuery from "../hooks/useSkillsQuery";
import { useEffectOnce } from "react-use";
import { Dictionary } from "../models";
import exercises from "../data/exercises/exercises";

const exerciseCount = Object.keys(exercises).length;

function getCompletedSkillsCount(skills: Dictionary<boolean>) {
  return Object.values(skills).reduce((prev, curr) => {
    if (curr) {
      return prev + 1;
    }

    return prev;
  }, 0);
}

function CalisthenicsSkillTree() {
  const { status: skillsStatus, error, data: skillsData } = useSkillsQuery();
  const { status: focusStatus, data: focusData } = useFocusQuery();
  const [hasMounted, setMountedState] = useState(false);

  useEffectOnce(() => {
    setMountedState(true);
  });

  if (!hasMounted) return null;

  if (error) {
    return (
      <div className="Content">
        <p className="CalisthenicsSkillTree_error-message">
          We had trouble retrieving your skills.
        </p>
        <p className="CalisthenicsSkillTree_error-message">
          <Link to="/contact-us/">
            Please let us know if this issue persists
          </Link>
        </p>
      </div>
    );
  }

  if (skillsStatus === "loading" || focusStatus === "loading" || !skillsData) {
    return (
      <div className="Content">
        <Loader />
        <h2 className="Content__refresh-message">
          Looks like our servers are having a little brain freeze.
        </h2>
        <p className="Content__refresh-message">
          If you've refreshed the page and you're still stuck, then{" "}
          <SupportPrompt>we'll be happy to help.</SupportPrompt>
        </p>
      </div>
    );
  }

  return (
    <div className="Content">
      <SkillProvider>
        <SkillTreeGroup theme={caliSkillsTreeTheme}>
          {({ handleFilter }: SkillGroupDataType) => {
            const completedSkillsCount = skillsData.skills
              ? getCompletedSkillsCount(skillsData.skills)
              : 0;

            return (
              <React.Fragment>
                <Confetti active={completedSkillsCount === exerciseCount} />
                <LoginPromptListener
                  totalSelectedSkillCount={completedSkillsCount}
                />
                <div>
                  <div className="SkillTree__StatsCardWrapper">
                    <StatsCard
                      skillCount={exerciseCount}
                      selectedSkillCount={completedSkillsCount}
                    />
                  </div>
                  <FilterInput handleChange={handleFilter} />
                </div>
                <SkillTreeContainers
                  focusedTreeId={focusData?.treeId ?? null}
                  userData={skillsData}
                />
              </React.Fragment>
            );
          }}
        </SkillTreeGroup>
      </SkillProvider>
    </div>
  );
}

export default CalisthenicsSkillTree;
