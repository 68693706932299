import React from "react";
import SkillsPageLayout from "../components/layouts/SkillsPageLayout";
import SEO from "../components/SEO";
import Introduction from "../components/Introduction";
import ErrorBoundary from "../components/errorBoundaries/ErrorBoundary";
import CalisthenicsSkillTree from "../components/CalisthenicsSkillTree";
import ReactGA from "react-ga";

ReactGA.event({
  category: "Application",
  action: "Load",
  label: "Skill tree has fully loaded",
  nonInteraction: true
});

function SkillTreePage() {
  return (
    <SkillsPageLayout>
      <SEO
        path="/skill-tree"
        description="Go through these step-by-step progressions and achieve jaw-dropping skills."
      />
      <Introduction />
      <ErrorBoundary>
        <CalisthenicsSkillTree />
      </ErrorBoundary>
    </SkillsPageLayout>
  );
}

export default SkillTreePage;
