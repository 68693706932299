import React from "react";

interface Props {
  children: React.ReactNode;
}

function SupportPrompt({ children }: Props) {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={() => window.$crisp.push(["do", "chat:open"])}
    >
      {children}
    </span>
  );
}

export default SupportPrompt;
