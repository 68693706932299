import { useQuery } from "react-query";
import getSkills from "../api/getUserSkillsByEmail";
import useAuth from "./useAuth";
import { UserSkills } from "../models";
import createUserSkills from "../api/createUserSkills";

type TKey = "skills";

interface TVariables {
  email: string;
  accessToken: string;
}

function useSkillsQuery() {
  const {
    getProfile,
    getUserId,
    getAccessToken,
    getAuthenticationState,
    tokenRenewalComplete
  } = useAuth();

  const profile = getProfile();
  const shouldFetch = getAuthenticationState() && tokenRenewalComplete;

  const email = shouldFetch && profile?.email ? profile.email : "";
  const userId = shouldFetch ? getUserId() : "";
  const accessToken = shouldFetch ? getAccessToken() : "";

  const { status, data, error } = useQuery<UserSkills, [TKey, TVariables]>(
    [
      "skills",
      {
        email,
        accessToken
      }
    ],
    getSkills,
    { enabled: shouldFetch }
  );

  if (error?.message?.toLowerCase().includes("no skill tree found")) {
    createUserSkills(userId, accessToken);
  }

  const result = {
    status,
    data,
    error
  };

  if (status === "idle") {
    result.data = {
      skills: {}
    };

    return result;
  }

  return {
    status,
    data,
    error
  };
}

export default useSkillsQuery;
