import React from "react";
import Tippy from "@tippy.js/react";

interface Props {
  content: React.ReactChild;
  isVisible: boolean;
  children: React.ReactElement<any>;
  placement?: "top" | "bottom";
  interactive?: boolean;
}

function ControlledTooltip(props: Props) {
  const {
    content,
    isVisible,
    children,
    placement = "top",
    interactive = false
  } = props;

  return (
    <Tippy
      interactive={interactive}
      content={content}
      placement={placement}
      duration={500}
      visible={isVisible}
      appendTo="parent"
    >
      {children}
    </Tippy>
  );
}

export default ControlledTooltip;
