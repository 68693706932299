import axios from "axios";

interface Args {
  accessToken: string;
  email: string;
}

async function getUserSkillsByEmail(key: string, args: Args) {
  const { accessToken, email } = args;
  const body = { email };

  const { data } = await axios.post(
    `${process.env.GATSBY_SERVER_URL}/users-by-email/user-skills`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return data;
}

export default getUserSkillsByEmail;
