import React from "react";
import "../../styles/SkillsPageItem.css";

interface Props {
  children: React.ReactNode;
}

function SkillsPageItem({ children }: Props) {
  return (
    <div className="SkillsPageItem">
      <div className="SkillsPageItem__content">{children}</div>
    </div>
  );
}

export default SkillsPageItem;
