import React from "react";
import SkillsPageItem from "./base/SkillsPageItem";
import useAuth from "../hooks/useAuth";
import { useWindowSize } from "react-use";
import { TreeIcon } from "../icons";
import Icon from "./Icon";

function FocusSkillTree() {
  const { getAuthenticationState } = useAuth();
  const { width } = useWindowSize();
  const isDesktop = width >= 900;

  if (!getAuthenticationState()) {
    return null;
  }

  return (
    <SkillsPageItem>
      <h3>Why don't you set a focus?</h3>
      {isDesktop && (
        <div style={{ width: "200px", margin: "0 auto" }}>
          <Icon src={TreeIcon} containerWidth={200} title="Tree" />
        </div>
      )}
      <p>
        Never lose sight of your goal. Focus on a skill tree by clicking its{" "}
        <span style={{ color: "#f54400" }}>☆</span>. We'll hoist your focused
        skill tree to the top of the page.
      </p>
    </SkillsPageItem>
  );
}

export default FocusSkillTree;
