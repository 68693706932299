function createUserSkills(userId: string, accessToken: string) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/user-skills`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      userId
    })
  });
}

export default createUserSkills;
