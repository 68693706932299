import React from "react";
import PageContent from "./PageContent";
import HSeparator from "../HSeparator";
import Footer from "../Footer";
import InfoSheet from "../InfoSheet/InfoSheet";
import NavRoutes from "../NavRoutes";

interface Props {
  children: React.ReactNode;
}

function SkillsPageLayout({ children }: Props) {
  return (
    <>
      <NavRoutes />
      <HSeparator />
      <PageContent hasSideSheet pageTitle="Your Skill Trees">
        {children}
        <InfoSheet />
      </PageContent>
      <Footer hasSideSheet />
    </>
  );
}

export default SkillsPageLayout;
