import React, { useState, useEffect } from "react";
import SkillsPageItem from "./base/SkillsPageItem";
import Select from "react-select";
import ReactGA from "react-ga";
import { navigate } from "gatsby";
import { standardProgressions, premiumProgressions } from "../data/filterInput";
import { OptionProps, ValueType } from "react-select/src/types";
import { FilterInputOption } from "../models";
import Button from "./Button";
import {
  defaultContainerStyles,
  getDefaultOptionStyles,
  defaultMenuStyles
} from "./forms/SelectInput";
import "../styles/FilterInput.css";
import useIsSubscriptionActive from "../hooks/useIsSubscriptionActive";

const customStyles = {
  container: () => ({
    ...defaultContainerStyles
  }),
  option: (_: unknown, state: OptionProps) => ({
    ...getDefaultOptionStyles(state.isFocused)
  }),
  menu: () => ({
    ...defaultMenuStyles
  })
};

interface Props {
  handleChange: (query: string) => void;
}

function handleFocus() {
  ReactGA.event({
    category: "Skills Filter",
    action: "Focused Filter Input",
    label: "Focused filter input"
  });
}

function FilterInput(props: Props) {
  const [link, setLink] = useState("#");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const isPremiumUser = useIsSubscriptionActive();

  const options = isPremiumUser
    ? [...standardProgressions, ...premiumProgressions]
    : standardProgressions;

  function handleChange(selectedOption: ValueType<FilterInputOption>) {
    if (!selectedOption) {
      setLink("#");
      return setSelectedLabel("");
    }

    setLink("#" + (selectedOption as FilterInputOption).value.treeId);
    return setSelectedLabel((selectedOption as FilterInputOption).label);
  }

  useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      if (e.keyCode === 13 && link !== "#") {
        goToTree();
      }
    }

    window.addEventListener("keypress", handleKeyPress);

    return function cleanup() {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [link]);

  function handleClick() {
    if (link !== "#") {
      goToTree();
    }
  }

  function goToTree() {
    props.handleChange(selectedLabel);
    setTimeout(() => {
      // to prevent a race condition with the above line
      navigate("/skill-tree/" + link);
    }, 350);

    return null;
  }

  return (
    <SkillsPageItem>
      <div className="FilterInput__container" style={{ margin: "16px auto" }}>
        <Select
          className="FilterInput"
          options={options}
          onChange={handleChange}
          onFocus={handleFocus}
          styles={customStyles}
          isClearable
          placeholder="Looking for a particular skill?"
        />
      </div>
      <Button disabled={selectedLabel === ""} handleClick={handleClick}>
        Go to skill
      </Button>
      <hr style={{ border: 0 }} />
    </SkillsPageItem>
  );
}

export default FilterInput;
