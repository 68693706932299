import React from "react";
import { Link } from "gatsby";
import "../styles/Introduction.css";

function Introduction() {
  return (
    <div className="Introduction__container">
      <div className="Introduction__content">
        <p className="Introduction__text">
          New to Cali Skills? <Link to="/tour/">Take the tour</Link>.
          <br />
          Want your own workout? Try the new{" "}
          <Link to="/workouts">In-App Workout feature</Link>.
        </p>
      </div>
    </div>
  );
}

export default Introduction;
