import { FilterInputOption } from "../../models";
import treeSchemas from "../skills";
import exercises from "../exercises/exercises";

interface Progressions {
  standardProgressions: FilterInputOption[];
  premiumProgressions: FilterInputOption[];
}

const defaultValue: Progressions = {
  standardProgressions: [],
  premiumProgressions: []
};

const progressions: Progressions = treeSchemas.reduce((prev, curr) => {
  const duplicates: Record<string, boolean> = {};

  const skills = Object.values(curr.keys).map(x => x.id);

  const currentProgression: FilterInputOption[] = skills
    .map(skill => {
      if (duplicates[skill]) return null;

      duplicates[skill] = true;

      const option: FilterInputOption = {
        label: exercises[skill].title,
        value: {
          treeId: curr.treeId,
          skillId: skill
        }
      };

      return option;
    })
    .filter((x): x is FilterInputOption => x !== null);

  if (curr.premium) {
    return {
      standardProgressions: prev.standardProgressions,
      premiumProgressions: [...prev.premiumProgressions, ...currentProgression]
    };
  }

  return {
    standardProgressions: [...prev.standardProgressions, ...currentProgression],
    premiumProgressions: prev.premiumProgressions
  };
}, defaultValue);

const { premiumProgressions, standardProgressions } = progressions;

export { premiumProgressions, standardProgressions };
