import React, { useState } from "react";
import SkillTreeContainer from "./SkillTreeContainer";
import skillTreeSchemas from "../data/skills";
import { UserSkills } from "../models";
import FocusSkillTree from "./FocusSkillTree";
import { useEffectOnce } from "react-use";
import TREE_KEYS from "../data/treeKeys";

interface Props {
  userData: UserSkills;
  focusedTreeId: string | null;
}

function SkillTreeContainers(props: Props) {
  const { userData, focusedTreeId } = props;
  const [orderedSkillTrees, setSkillTreeOrder] = useState(skillTreeSchemas);
  const defaultOpenTreeId = focusedTreeId ? focusedTreeId : TREE_KEYS.CORE_ONE;

  useEffectOnce(() => {
    const sortedTrees = skillTreeSchemas.sort(a => {
      if (a.treeId === focusedTreeId) return -1;

      return 1;
    });

    setSkillTreeOrder(sortedTrees);
  });

  return (
    <>
      {!focusedTreeId && <FocusSkillTree />}
      {orderedSkillTrees.map(schema => {
        return (
          <SkillTreeContainer
            key={schema.treeId}
            closedByDefault={schema.treeId !== defaultOpenTreeId}
            skillTreeSchema={schema}
            savedData={userData.skills}
          />
        );
      })}
    </>
  );
}

export default SkillTreeContainers;
