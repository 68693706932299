import { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { navigate } from "gatsby";
import useAuth from "../../hooks/useAuth";
import generateSnackbar from "../../helpers/generateSnackbar";

const LOGIN_PROMPT_THRESHOLD = 3;

interface Props {
  totalSelectedSkillCount: number;
}

function handleSnackbarAction() {
  ReactGA.event({
    category: "Authentication",
    action: "Click login on snackbar",
    label: "Has clicked login on snackbar prompt"
  });
  navigate("/login");
  return null;
}

function LoginPromptListener({ totalSelectedSkillCount }: Props) {
  const { getAuthenticationState } = useAuth();
  const [hasDisplayedSnackbar, setSnackbarDisplayedState] = useState(false);

  useEffect(() => {
    if (getAuthenticationState()) {
      return;
    }

    if (hasDisplayedSnackbar) {
      return;
    }

    if (totalSelectedSkillCount < LOGIN_PROMPT_THRESHOLD) {
      return;
    }

    setSnackbarDisplayedState(true);

    ReactGA.event({
      category: "Application",
      action: "Display login prompt",
      label: "Selected 3 or more skills and is presented with login prompt",
      nonInteraction: true
    });

    generateSnackbar({
      message: "Keep up the good work! Sign up to save your progress.",
      actionMessage: "Sign up",
      handleAction: handleSnackbarAction,
      type: "info"
    });
  }, [totalSelectedSkillCount]);

  return null;
}

export default LoginPromptListener;
